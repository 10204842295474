import { InteractionType, RedirectRequest } from "@azure/msal-browser"
import {
  MsalAuthenticationTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react"
import { navigate } from "gatsby-link"
import * as React from "react"
import { useEffect, useState } from "react"
import { QueryClient, QueryClientProvider, useMutation } from "react-query"
import { toast } from "react-toastify"
import LoadingModal from "../../components/Modal/LoadingModal"
import { loginRequest } from "../../components/Security/authConfig"
import { chipButton, down } from "../../components/Styling/Buttons"
import { ErrorComponent } from "../../components/Utils/ErrorComponent"
import { GetProfile } from "../../components/Utils/ReactQueries"
import { patchApi, postApi } from "../../components/Utils/WebApi-utils"
import hefinn from "../../images/hefinn.png"

const queryClient = new QueryClient()

export type Order = {
  PriceLevelId: string
  Name: string
  CustomerId: string
  Source: string
  OrderLines: OrderLines[]
}
export type OrderLines = {
  Unit: string
  Quantity: number
  UserId: string
  ProductNumber: string
}

const SubscriptionContent = () => {
  const { instance, accounts, inProgress } = useMsal()
  const [loadState, setLoadState] = useState(false)
  const [otherSociety, setOtherSociety] = useState("")
  const [classYes, setClassYes] = useState(chipButton + down)
  const [classNo, setClassNo] = useState(chipButton + down)
  const [classUnclear, setClassUnclear] = useState(chipButton + down)

  const userProfile = GetProfile(accounts, inProgress, instance)

  let orderObject: Order | any = {
    PriceLevelId: process.env.GATSBY_APP_ORDER_PRICELEVEL!,
    Name:
      process.env.GATSBY_APP_ORDER_NAME_HEF + userProfile?.data?.customerNumber,
    CustomerId: userProfile.data?.id,
    Source: "MinSide",
    OrderLines: [
      {
        Unit: process.env.GATSBY_APP_ORDERLINE_UNIT!,
        Quantity: 1,
        UserId: userProfile.data?.id,
        ProductNumber: "HEF",
      },
    ],
  }

  let contactObject: any = {
    PhilosophicalSociety: otherSociety,
  }

  const UpdateUser = useMutation(
    async values =>
      patchApi(
        process.env.GATSBY_APP_WEBAPI + "HefContacts/me",
        values,
        accounts,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: (data, values) => {
        //queryClient.setQueryData(["profile"], data)
        queryClient.invalidateQueries(["profile"])
      },
      onError: data => {
        toast.error("Noe gikk galt!")
      },
    }
  )

  const onSubmitOrder = values => {
    // values.name = orderObject.RecruitCode = values.recruitCode
    UpdateUser.mutate(contactObject)
    PostOrder.mutate(orderObject)
    setLoadState(true)
  }

  const PostOrder = useMutation(
    async values =>
      postApi(
        process.env.GATSBY_APP_WEBAPI + "HefSalesOrder/Order",
        values,
        accounts,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: (data, values) => {
        //queryClient.setQueryData(["profile"], data)
        navigate("/velkommen")
      },
      onError: data => {
        console.log(data)
        toast.error("Noe gikk galt med orderen!")
        setLoadState(false)
      },
    }
  )
  console.log(location.pathname)
  return (
    <div>
      <div className="lg:flex lg:justify-center md:flex md:justify-center">
        <div className="px-8 pb-16 pt-8 lg:w-1/2  lg:flex lg:flex-col md:w-2/3 md:flex md:flex-col items-center">
          {loadState ? (
            <LoadingModal />
          ) : (
            <div className="lg:justify-center md:justify-center lg:w-full">
              <div className="flex space-x-2 mb-4">
                <div className="lg:w-8 lg:h-8 w-6 h-6 bg-gray rounded-full  flex justify-center items-center text-blue text-lg border border-blue">
                  1
                </div>
                <div className="lg:w-8 lg:h-8 w-6 h-6 bg-blue rounded-full flex justify-center items-center text-white text-lg">
                  2
                </div>
              </div>
              <h1
                className="text-xl font-serif text-blue justify-self-center mb-12"
                id="membership-other-society"
                aria-labelledby="membership-other-society"
              >
                Er du medlem i andre tros- og livssynssamfunn?
              </h1>

              <div className="flex space-x-4">
                <label className="inline-block cursor-pointer">
                  <input
                    className="hidden"
                    type="radio"
                    id="other-society-yes"
                    name="other-society"
                    onClick={() => {
                      setOtherSociety("yes")
                      setClassYes(classYes + " bg-blue text-white")
                      setClassNo(chipButton + down)
                      setClassUnclear(chipButton + down)
                    }}
                  />
                  <span className={classYes}>Ja</span>
                </label>

                <label className="inline-block cursor-pointer">
                  <input
                    className="hidden"
                    type="radio"
                    id="other-society-no"
                    name="other-society"
                    onClick={() => {
                      setOtherSociety("no")
                      setClassNo(classNo + " bg-blue text-white")
                      setClassYes(chipButton + down)
                      setClassUnclear(chipButton + down)
                    }}
                  />
                  <span className={classNo}>Nei</span>
                </label>

                <label className="inline-block cursor-pointer">
                  <input
                    className="hidden"
                    type="radio"
                    id="other-society-unclear"
                    name="other-society"
                    onClick={() => {
                      setOtherSociety("unclear")
                      setClassUnclear(classUnclear + " bg-blue text-white")
                      setClassYes(chipButton + down)
                      setClassNo(chipButton + down)
                    }}
                  />
                  <span className={classUnclear}>Veit ikke</span>
                </label>
              </div>
              <div>
                <img src={hefinn} alt="" />
              </div>
              <div>
                <p>
                  Medlemskapet ditt og støtten vi får, forutsetter at du melder
                  deg ut av andre tros- og livssynssamfunn. Dette kan du gjøre
                  senere.
                </p>
              </div>
              <div className="w-full grid grid-cols-5 gap-0 mt-10 items-center">
                <button
                  className="flex text-blue justify-self-start text-base col-span-2 text-xs space-x-2 "
                  onClick={() => navigate("/hefinn/mine-opplysninger")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-1 h-5 w-5 text-xl font-bold font-serif transition-all rotate-90 "
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Tilbake
                </button>
                <label className=" flex text-center justify-center text-base col-span-1">
                  <label className="font-bold">2</label>/2
                </label>
                <button
                  className="text-blue  justify-self-end text-xs flex col-span-2 self-center"
                  onClick={onSubmitOrder}
                >
                  Bli medlem nå
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="ml-1 h-5 w-5 text-xl font-bold font-serif transition-all -rotate-90 self-center"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const Subscription = props => {
  const authRequest: RedirectRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      // loadingComponent={LoadingModal}
    >
      <QueryClientProvider client={queryClient}>
        <SubscriptionContent {...props} />
      </QueryClientProvider>
    </MsalAuthenticationTemplate>
  )
}
export default Subscription
